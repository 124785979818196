button.info {
  vertical-align: middle;
  position: relative;
  width: 1.2em;
  height: 1.2em;
  text-indent: -9999em;
  display: inline-block;
  color: white;
  font-weight: bold;
  font-size: 1em;
  line-height: 1em;
  background-color: black;
  margin-left: 0.25em;
  -webkit-border-radius: 0.75em;
  -moz-border-radius: 0.75em;
  border-radius: 0.75em;
  padding: 0;
  border: 0;
}
button.info:hover {
  background-color: #666f79;
  cursor: hand;
  cursor: pointer;
}
button.info:before {
  content: "?";
  position: absolute;
  top: 0.2em;
  left: 0;
  text-indent: 0;
  display: block;
  width: 1.2em;
  text-align: center;
}
.popover-title {
  font-weight: bold;
}
label div.popover.fade.in {
  opacity: 100%;
  -webkit-transition: opacity 0.2s ease;
  -moz-transition: opacity 0.2s ease;
  transition: opacity 0.2s ease;
}
label:hover div.popover.fade.in {
  opacity: 1;
}
div.justify {
  position: relative;
  font-size: 14px;
  color: black;
  width: 260px;
  text-align: left;
  padding: 2px;
}