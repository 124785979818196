body {
  padding-top: 2.8rem;
  margin: 0;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #212121 !important;
  color: #E0E0E0 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.cursorHand {
  cursor: pointer;
}

.MuiInputBase-input {
  color: #E0E0E0 !important;
}

.MuiSelect-icon {
  color: #E0E0E0 !important;
}

.MuiSvgIcon-root {
  color: #E0E0E0 !important;
}

.checkBoxTabel>span .MuiSvgIcon-root {
  color: #c30404 !important;
}

.basic-navbar-nav {
  color: #E0E0E0 !important;
}

input {
  color: #E0E0E0 !important;
}

.MuiCheckbox-root {
  color: #E0E0E0 !important;
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: rgb(195, 4, 4) !important;
}

textarea {
  color: #E0E0E0 !important;
}

.MuiInput-underline:before {
  border-bottom: 1px solid #E0E0E0 !important;
}

.MuiInput-underline:after {
  border-bottom: 1px solid rgb(195, 4, 4) !important;
}

.MuiButton-containedPrimary,
.MuiButton-containedPrimary:hover {
  background-color: rgb(195, 4, 4) !important;
  color: #ffffff !important;
}

fieldset>legend {
  color: #E0E0E0 !important;
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #E0E0E0;
  -webkit-box-shadow: 0 0 0px 1000px #212121 inset;
  transition: background-color 5000s ease-in-out 0s;
}


.checkBox {
  color: #E0E0E0 !important;
}

.MuiOutlinedInput-notchedOutline {
  border-color: #E0E0E0 !important;

}

label {
  color: #E0E0E0 !important;
}

.paper {
  margin-top: 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
}


.avatar {
  margin: 8px;
  background-color: rgb(195, 4, 4) !important;
}

.form {
  width: 100%;
  margin-top: 8px;
}

.submit {
  margin: 24px 0 16px !important;
  background-color: rgb(195, 4, 4) !important;
}

a {
  color: white !important;
  font-weight: bold;
}

.spinner {
  position: fixed;
  left: 50%;
  top: 50%;
  margin: 10px 0 10px;
  z-index: 9999;
}

.bg-navbar {
  background-color: #040716;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background-color: #30323a !important;
  border-color: #dee2e6 #dee2e6 #fff !important;
}

.modal-content {
  background-color: #384161 !important;
}

.MuiSwitch-colorSecondary.Mui-checked {
  color: #c30404 !important;
}

.MuiSwitch-colorSecondary.Mui-checked+.MuiSwitch-track {
  background-color: #c30404 !important;
}

.btnTable {
  padding: 11px 15px;
}

.navbar-light .navbar-toggler-icon {
  background-image: url("/navbar.png");
}

.MuiButton-outlinedPrimary {
  color: #c30404 !important;
  border: 1px solid rgb(195, 4, 4) !important;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background-color: #c30404 !important;
  border-color: #dee2e6 #dee2e6 #fff !important;
  color: #fff !important;
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mg-top64 {
  margin-top: 64px;
}

.hrefOutlet {
  color: #f2f2f2 !important;
  font-size: 1.5em;
  margin: 0 auto 0;
  text-decoration: underline;
}

.MuiFormLabel-root {
  color: #fff !important;
}

.garis-bawah {
  border-bottom: 1px solid #e0e0e0;
  padding: 5px 0px;
}

.navbar {
  padding: 0px 15px !important;
  margin: -4px 0px !important;
}

.MuiTablePagination-actions {
  margin-left: -50px !important;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: transparent;
}

.MuiDialog-paper {
  background-color: #1a1d20 !important;
}

.MuiDialog-paperWidthSm {
  min-width: 450px !important;
  ;
}

.MuiButton-textPrimary {
  color: rgba(195, 4, 4) !important;
}

.colorWhite {
  color: #fff !important;
}

.colorBlack {
  color: #000 !important;
}
.fixHeaderModal > .colorBlack {
  color: #fff !important;
}
.bodySessionDetail  > .colorBlack {
  color: #fff !important;
}

@media (min-width: 768px) {
  .MuiTablePagination-caption {
    padding-right: 35px !important;
  }
}

.skeleton-line>span>span.css-19bon8n {
  height: 1px;
}

.colorRed {
  color: #c62828 !important;
}

.sizeIconHapusOutlet {
  font-size: 2.2em !important;
}

.marginLeft20 {
  margin-left: 20px !important;
}

.tabelWhite>th,
.tabelWhite>td {
  color: #fff !important;
}

.btn-primary {
  background-color: #c30404 !important;
  border-color: #c30404 !important;
}

.btnAddManual {
  background-color: #006db3 !important;
  margin-right: 10 !important;
  color: #fff !important;
  width: 100% !important;
}

.btnImportExcel {
  background-color: #99aa00 !important;
  margin-right: 10 !important;
  color: #fff !important;
  width: 100% !important;
}

.btnImportExcelCatatan {
  background-color: #005b4f !important;
  margin-right: 10 !important;
  color: #fff !important;
  width: 100% !important;
}

.btnAddMultiple {
  background-color: #00867d !important;
  margin-right: 10 !important;
  color: #fff !important;
  width: 100% !important;
}

.MuiTypography-body2 {
  color: #fff !important;
}

.MuiPickersSlideTransition-transitionContainer>* {
  color: #fff !important;
}

.MuiPickersCalendarHeader-dayLabel {
  color: #fff !important;
}

.MuiPickersToolbar-toolbar {
  background-color: #f89b35 !important;
}

.MuiTypography-subtitle1 {
  color: #fff !important;
}

.MuiPickersCalendarHeader-iconButton {
  background-color: transparent !important;
}

.MuiPickersDay-daySelected {
  background-color: #f89b35 !important;
}

.MuiPickersModal-dialogRoot {
  min-width: 310px !important;
}

.tangan {
  cursor: pointer;
}

.paadingRightLeft15 {
  padding: 0 15px;
}

.MuiAppBar-colorPrimary {
  position: fixed !important;
  color: #fff;
  background-color: rgb(195, 4, 4) !important;
  /* background-color: #c7783f !important; */
}

.DialogFont {
  color: #fff !important;
}

.tabelForm>div .MuiFormControl-root>input .MuiInputBase-input {
  color: #000 !important;
}

.marginTop15 {
  margin-top: 15px !important;
}

.bodyDialog {
  margin-top: 60px
}

.MuiDialog-paperFullScreen {
  min-width: 320px !important;
}

.MuiTab-textColorPrimary.Mui-selected {
  color: #fff !important;
}

.MuiTab-textColorPrimary {
  color: #fff !important;
}

.MuiTabs-centered {
  /* border-bottom: 2px solid #ded6d6; */
  background-color: #c7783f;
}

.MuiTabs-indicator {
  background-color: #fff !important;
}

button:focus {
  outline: 0px auto !important;
}

.MuiTabs-root {
  margin-top: -9px !important;
}

.bgTabel {
  background-color: #ecf0f1;
}

.MuiDialogActions-root {
  border-top: 1px solid white;
}

.MuiDialogTitle-root {
  border-bottom: 1px solid white;
}

.bgContent-orderan {
  background-color: #2e3133;
  padding: 10px;
}

.ubahTanggal {
  font-size: 14px;
  color: #3856f3;
  cursor: pointer;
  font-weight: bold;
  font-style: oblique;
}

.bgRowCancel {
  background-color: #fb4646;
}

.MuiDialog-paperWidthSm {
  min-width: 310px !important;
}

.bgRowCancel:hover {
  background-color: #ec2828 !important;
}

.font12 {
  font-size: 12px !important;
}

.btnBack {
  color: rgba(195, 4, 4);
  cursor: pointer;
}

.pilihOutlet {
  margin-top: -25px;
  color: #ecf0f1;
}

.manajemenOutlet {
  margin-top: 15px;
  color: #ecf0f1;
}

.btnRiwayatTrans {
  background-color: #c30404 !important;
  margin-right: 10;
  color: #fff !important;
}

.bgPilihOutlet {
  background-color: #c30404;
}

/* Laporan */

.bg-totPenerimaan {
  background-color: #00701a !important;
  color: #fff;
}

.bg-todTrans {
  background-color: #524c00 !important;
  color: #fff;
}

.bg-todCabang {
  background-color: #005662 !important;
  color: #fff;
}

.bg-pembayaran {
  background-color: #003d33 !important;
  color: #fff;
}

.bg-laporan {
  background-color: #f3f3f3 !important;
  color: #333;
}

.judul-kotak {
  border-color: #e7eaec;
  border-image: none;
  border-style: solid solid none;
  border-width: 0px 0 0;
  margin-bottom: 0;
  padding: 14px 15px 7px;
  min-height: 48px;
}

.box {
  clear: both;
  margin-bottom: 25px;
  margin-top: 0;
  padding: 0;
}

.right {
  float: right !important;
}

.label {
  display: inline;
  padding: .2em .6em .3em;
  font-size: 55%;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25em;
}

.box-content {
  padding: 15px 20px 20px 20px;
  border-color: #e7eaec;
  border-image: none;
  border-style: solid solid none;
  border-width: 1px 0;
}

.isiBayar {
  font-size: 20px;
  font-weight: bold;
}

.colorIconBranch {
  color: #333 !important;
}

.box-report {
  padding: 15px 20px 20px 20px;
  border-color: #e7eaec;
  border-image: none;
  border-style: solid solid none;
  border-width: 1px 0;
  height: 110px;
}

.bg-report1 {
  background-color: #324851 !important;
  color: #fff;
}

.bg-report2 {
  background-color: #86AC41 !important;
  color: #fff;
}

.bg-report3 {
  background-color: #34675c !important;
  color: #fff;
}

.prev,
.next {
  background-color: #c30404;
}

.active-btnGroup {
  background-color: #924f16 !important;
}

.active-btnGroup:hover {
  background-color: #924f16 !important;
}

.MuiButtonGroup-groupedContainedPrimary:not(:last-child) {
  border-right: 1px solid #924f16 !important;
}


th {
  text-align: none !important;
}

.borderMenu {
  border: 3px solid #c30404;
  padding: 40px 15px 0px;
  margin: 10px 1px;
}

.menuName {
  margin-top: -60px;
  text-align: center;
}

.menuName>span {
  font-size: 24px;
  color: #ecf0f1;
  font-weight: bold;
  background-color: #212121;
  padding: 0 7px;
}



.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
  color: #ffffff !important;
  background-color: #c30404;
}

.btnColor,
.hrefColor {
  color: #c30404 !important;
}

.bgHeaderTabel {
  background-color: #c30404 !important;
}

.MuiPickerDTTabs-tabs {
  background-color: #f89b35  !important;
}

.MuiPickersClock-clock {
  background-color: rgba(255, 255, 255, 0.07) #d66b11 !important;
}

.MuiPickersClockPointer-pointer {
  background-color: #f89b35 !important;
}

.MuiPickersClock-pin {
  background-color: #f89b35 !important;
}

.MuiPickersClockNumber-clockNumber {
  color: rgba(249, 245, 245, 0.87) !important;
}

.MuiPickersClockPointer-thumb {
  border: 14px solid #f89b35 !important;
}
.table-hover tbody tr:hover {
  background-color: rgb(204, 204, 204) !important;
  color: #131212 !important;
}

.boderSession {
  border-top: 3px solid #fff !important;
}
.bgModal{
  background-color: #383e48!important;
}
.boderSession {
  border-top: 3px solid #fff !important;
}
.col-md-1,.col-2 > .MuiSvgIcon-root {
  color: #ff1300  !important;
}
button > .MuiIconButton-label > .MuiSvgIcon-root {
  /* color: rgb(37, 37, 37)  !important; */
}
.MuiGrid-root > button > .MuiIconButton-label > .MuiSvgIcon-root {
  color: #fff  !important;
}

.bodySessionDetail  > .table-responsive .table-bordered td, .table-bordered th {
  /* border: 0px solid #dee2e6 !important; */
}


.buttonExportExcel {
  color: #fff;
  background-color: #c30404;
  padding: 5px;
  font-size: 16px;
  font-weight: bold;
}

.bgHeaderTabel>th .MuiTableSortLabel-root.MuiTableSortLabel-active {
  color: #fff;
}

.bgHeaderTabel>th .MuiTableSortLabel-root:hover {
  color: #fff;
}

.menuLaporan>.navbar {
  background-color: #c30404;
}

.namaLaporan{
  color: black;
  margin-top: 10px;
  text-align: center;
}
@media (max-width: 767px) {

  .nav-pills .nav-link.active,
  .nav-pills .show>.nav-link {
    color: #3e3737 !important;
    background-color: #f1f0f0;
  }

  .nav-item>.nav-link {
    color: #f1f0f0 !important;
  }
}

@media (min-width: 768px) {

  .nav-pills .nav-link.active,
  .nav-pills .show>.nav-link {
    color: #ffffff !important;
    background-color: #c30404;
  }

  .nav-item>.nav-link {
    color: #fff !important;
  }

}
#laporanVoid > thead > tr >td {
  color: #fff !important;
}
#laporanPerSession > thead > tr >th, tbody > tr >th {
  color: #fff !important;
}
.sessionDetail  .table-bordered td, .sessionDetail  .table-bordered th {
  border: 0px solid #dee2e6 !important;
}
#laporanPerSession > tbody > tr {
  color: #fff !important;
}
#laporanPerSession > tbody > tr :hover {
  color: #000 !important;
}
.MuiTableCell-root{
  color: #333131 !important;
}
table tbody tr:nth-child(odd) {
  background-color: white !important;
}
table tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}
iframe{
  display: none !important;
}
.mgTop10{
  margin-top: 10px !important;
}
.bgRound{
  background-color: none;
}